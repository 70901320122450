<template>
  <ProjectConfigureForm @formSubmitted="saveProject" :editing="true" :project="project" />
</template>

<script>
import ProjectConfigureForm from '@/components/Projects/ProjectConfigureForm'
import {mapActions} from 'vuex'
import createProjectModel from '@/utils/projects/createProjectModel'

export default {
  name: 'EditProject',
  components: {ProjectConfigureForm},
  data() {
    return {
      project: createProjectModel(),
      loading: false,
    }
  },
  created() {
    this.getProject()
  },
  methods: {
    ...mapActions(['getProjectByID', 'updateProject', 'showNotification']),
    async getProject() {
      const projectID = this.$route.params.project_id
      this.project = await this.getProjectByID(projectID)
    },
    async saveProject() {
      try {
        this.loading = true
        await this.updateProject(this.project)
        this.$router.push({name: 'Projects'})
        this.loading = false
        this.showNotification({
          type: 'success',
          message: 'NOTY_PROJECT_UPDATED',
        })
      } catch (e) {
        this.showNotification({
          type: 'error',
          message: e.response.data,
        })
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
